













import {Component,Prop} from 'vue-property-decorator';
import Vue from 'vue';

@Component({
    components:{
       
    }
})
export default class BannerTitled extends Vue { 
    @Prop() title? : string;
    @Prop() subtitle? : string;
}

