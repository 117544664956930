







import {Component,Prop} from 'vue-property-decorator';
import Vue from 'vue';
@Component({})
export default class Video extends Vue {
    @Prop() source! : string;
}
